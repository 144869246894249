import { gql } from '@apollo/client'
import {
  json,
  Form,
  ActionFunction,
  redirect,
  useNavigation,
  LoaderFunction,
  useLoaderData,
} from 'react-router-dom'

import { User } from '../../../types'
import { graphql } from '../api'
import Button from '../components/Button'
import ErrorBoundary from '../components/ErrorBoundary'
import Input from '../components/Input'

export const path = '/perfil'

export const loader: LoaderFunction = async () => {
  const user = await graphql.query({
    query: gql`
      query {
        me {
          id
          email
          name
        }
      }
    `,
    fetchPolicy: 'no-cache',
  })

  return json(user.data.me)
}

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()

  const user = Object.fromEntries(formData.entries())

  const response = await graphql.mutate({
    mutation: gql`
      mutation ($user: UpdateProfileInput!) {
        updateProfile(user: $user) {
          id
        }
      }
    `,
    variables: {
      user,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })

  if (response.errors) {
    return json({ errors: response.errors })
  }

  return redirect('/perfil')
}

export const handle = {
  title: ({ data }: { data: User }) => data.name,
}

function Component() {
  const loaderData = useLoaderData() as User
  const navigation = useNavigation()

  return (
    <div style={style.div}>
      <Form method='post' style={style.form}>
        <h2>Perfil</h2>
        <Input legend='Nome' name='name' defaultValue={loaderData.name} />
        <Input legend='Email' type='email' value={loaderData.email} readOnly />
        <Input legend='Nova senha' name='password' type='password' />
        <Button isLoading={navigation.state === 'submitting'} type='submit'>
          Atualizar perfil
        </Button>
      </Form>
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    minWidth: '400px',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const errorElement = <ErrorBoundary />

export const element = <Component />
