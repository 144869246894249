import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { ThemeProvider } from 'caixa'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { graphql } from './api'
import * as Authenticate from './routes/Authenticate'
import * as Root from './routes/Root'

import './index.css'

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://74c6232568cf4a5f90a54acba7cce3ef@o4505235504758784.ingest.sentry.io/4505247170363392',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

const router = createBrowserRouter([Authenticate, Root])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Toaster />
    <ApolloProvider client={graphql}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
)
