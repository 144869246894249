import { Box } from 'caixa'
import { Form, useLocation } from 'react-router-dom'

const Action: React.FC<{
  action: string
  itemKey: string
  label: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  type?: string
  onClick?: React.DOMAttributes<HTMLLIElement>['onClick']
}> = ({ action, label, icon: Icon, itemKey, type, onClick }) => {
  const location = useLocation()
  return (
    <Box as='li' onClick={onClick}>
      <Form action={location.pathname} method='post'>
        <input type='hidden' name='key' value={itemKey} />
        <input type='hidden' name='type' value={type} />
        <Box
          cursor='pointer'
          display='flex'
          flexDirection='column'
          position='relative'
        >
          <input type='hidden' name='action' value={action} />
          <Icon
            style={{
              color: '#a01d21',
              height: '1.5rem',
              width: '1.5rem',
              position: 'relative',
              alignSelf: 'center',
            }}
          />
          <Box as='label' alignSelf='center' fontSize='1.15rem'>
            {label}
          </Box>
          <Box
            cursor='pointer'
            as='button'
            type='submit'
            position='absolute'
            top='0'
            left='0'
            right='0'
            bottom='0'
            opacity='0'
          ></Box>
        </Box>
      </Form>
    </Box>
  )
}

export default Action
