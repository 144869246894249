const Anchor: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = (props) => (
  <a
    {...props}
    style={{ ...props.style, ...style }}
    children={props.children}
  />
)

const style = {
  color: '#a01d21',
  fontWeight: 'bold',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
} satisfies React.CSSProperties

export default Anchor
