import { gql } from '@apollo/client'
import { useState } from 'react'
import {
  json,
  Form,
  ActionFunction,
  redirect,
  useNavigation,
} from 'react-router-dom'

import { graphql } from '../../api'
import Avatar from '../../components/Avatar'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ErrorBoundary from '../../components/ErrorBoundary'
import Input from '../../components/Input'

export const path = '/clientes/adicionar'

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()

  const { avatar, ...customer } = Object.fromEntries(formData.entries())

  const response = await graphql.mutate({
    mutation: gql`
      mutation ($customer: CreateCustomerInput!) {
        createCustomer(customer: $customer) {
          id
        }
      }
    `,
    variables: {
      customer,
    },
    errorPolicy: 'all',
  })

  if (!response.data?.createCustomer?.id) {
    if (response.errors) {
      return json({ errors: response.errors })
    }
  }

  if (avatar instanceof File && avatar.name) {
    const {
      data: { getSignedUrl },
    } = await graphql.mutate({
      mutation: gql`
        mutation ($key: String!) {
          getSignedUrl(key: $key) {
            key
            signedUrl
          }
        }
      `,
      variables: {
        key: `${response.data.createCustomer.id}/${avatar.name}`,
      },
    })

    await fetch(getSignedUrl.signedUrl, {
      method: 'put',
      body: avatar,
    })

    await graphql.mutate({
      mutation: gql`
        mutation ($key: String!, $deletedAt: String) {
          updateFile(key: $key, deletedAt: $deletedAt) {
            key
          }
        }
      `,
      variables: {
        key: getSignedUrl.key,
        deletedAt: `${+new Date()}`,
      },
    })
    customer.avatar = getSignedUrl.key

    await graphql.mutate({
      mutation: gql`
        mutation ($customer: UpdateCustomerInput!) {
          updateCustomer(customer: $customer) {
            id
          }
        }
      `,
      variables: {
        customer: {
          id: response.data.createCustomer.id,
          avatar: getSignedUrl.key,
        },
      },
      errorPolicy: 'all',
    })
  }

  return redirect(`/cliente/${response.data.createCustomer.id}`)
}

export const handle = {
  breadcrumb: () => <Breadcrumb to='/clientes/adicionar'>Adicionar</Breadcrumb>,
  title: () => 'Adicionar cliente',
}

function Component() {
  const navigation = useNavigation()
  const [alt, setAlt] = useState('C')

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target
    if (value) setAlt(value.charAt(0).toUpperCase())
    else setAlt('C') // C for copertec
  }

  return (
    <div style={style.div}>
      <Form method='post' style={style.form} encType='multipart/form-data'>
        <h2>Dados da empresa</h2>
        <Avatar alt={alt} />
        <Input legend='Nome da empresa' name='name' onChange={handleChange} />
        <Button isLoading={navigation.state === 'submitting'} type='submit'>
          Adicionar cliente
        </Button>
      </Form>
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
  },
  nav: {},
  form: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    minWidth: '400px',
  },
  ul: {
    display: 'flex',
    gap: '1rem',
  },
  li: {
    backgroundColor: 'white',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
    padding: '2rem',
    width: '100%',
    outlineColor: '#a01d21',
    color: 'gray',
    position: 'relative',
  },
  svg: {
    color: '#a01d21',
    height: '2rem',
    width: '2rem',
  },
  a: {
    position: 'absolute',
    color: 'black',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  img: {
    maxWidth: '10rem',
    width: '100%',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export const errorElement = <ErrorBoundary />

export const element = <Component />
