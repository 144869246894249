import { useEffect } from 'react'
import { useMatches } from 'react-router-dom'

const Title = () => {
  const matches = useMatches()

  const title = matches
    .filter(
      (match) =>
        typeof match.handle === 'object' &&
        match.handle !== null &&
        'title' in match.handle,
    )
    .map((match) =>
      (match.handle as { title: (match: unknown) => string }).title(match),
    )
    .at(-1)

  useEffect(() => {
    if (title) {
      document.title = title
    } else {
      document.title = 'Copertec - Engenharia e Inspeção'
    }
  }, [title])

  return null
}

export default Title
