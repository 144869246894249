import Cookies from 'js-cookie'
import decode from 'jwt-decode'
import {
  ActionFunction,
  Form,
  json,
  LoaderFunction,
  redirect,
  useActionData,
  useNavigation,
} from 'react-router-dom'

import { rest } from '../api'
import copertec from '../assets/copertec.png'
import Alert from '../components/Alert'
import Anchor from '../components/Anchor'
import Button from '../components/Button'
import Input from '../components/Input'

export const path = '/entrar'

export const id = 'authenticate'

export const loader: LoaderFunction = async () => {
  const session = Cookies.get('copertec_jwt')

  if (session) {
    const decoded = decode<{ aud?: string }>(session)
    if (decoded?.aud === 'ADMIN') return redirect('/')
    if (decoded?.aud === 'USER') return redirect('/arquivos')
    Cookies.remove('copertec_jwt')
  }

  return json({})
}

export const action: ActionFunction = async ({ request }) => {
  const formData = await request.formData()
  const email = formData.get('email')?.toString()
  const password = formData.get('password')?.toString()

  const errors: Partial<Record<'email' | 'password' | 'error', string>> = {}

  if (typeof email !== 'string' || !email) errors.email = 'Email é obrigatório'

  if (typeof password !== 'string' || !password)
    errors.password = 'Senha é obrigatória'

  if (Object.keys(errors).length) return json({ errors })

  const response = await rest('authenticate', {
    body: JSON.stringify({ email, password }),
    credentials: 'include',
    method: 'post',
  })

  const data = await response.json()

  if (data.error) {
    return json({ errors: data })
  }

  if (data.role === 'ADMIN') return redirect('/')

  return redirect('/arquivos')
}

function Component() {
  const actionData = useActionData() as { errors?: Record<string, string> }
  const navigation = useNavigation()

  return (
    <div style={style.div}>
      <main style={style.main}>
        <img alt='Copertec' src={copertec} style={style.img} />
        <h1>Acesse sua conta</h1>
        <p style={style.p}>
          Caso tenha problemas para acessar, entre em contato pelo email{' '}
          <Anchor href='mailto:suporte@copertec-eng.com.br'>
            suporte@copertec-eng.com.br
          </Anchor>
          .
        </p>
        <Form method='post' style={style.form}>
          <Input
            legend='Email'
            message={actionData?.errors?.email}
            name='email'
            type='email'
          />
          <Input
            legend='Senha'
            message={actionData?.errors?.password}
            name='password'
            type='password'
          />
          {actionData?.errors?.error ? (
            <Alert>{actionData.errors.error}</Alert>
          ) : null}
          <Button isLoading={navigation.state !== 'idle'} type='submit'>
            Entrar
          </Button>
        </Form>
        <p style={style.p}>
          Esqueceu a senha? Entre em contato com{' '}
          <Anchor href='mailto:suporte@copertec-eng.com.br'>
            suporte@copertec-eng.com.br
          </Anchor>
          .
        </p>
      </main>
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    width: '100%',
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: '20rem',
    width: '100%',
  },
  img: {
    maxWidth: '16rem',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  p: {
    color: 'gray',
    textAlign: 'center',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const element = <Component />
