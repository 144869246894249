import { gql } from '@apollo/client'
import {
  json,
  Form,
  ActionFunction,
  redirect,
  useNavigation,
  RouteMatch,
  LoaderFunction,
  useLoaderData,
} from 'react-router-dom'

import { User } from '../../../../types'
import { graphql } from '../../api'
import TrashIcon from '../../assets/trash.svg?react'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ErrorBoundary from '../../components/ErrorBoundary'
import Input from '../../components/Input'

export const path = '/cliente/:customerId/usuarios/:userId'

export const loader: LoaderFunction = async ({ params }) => {
  const response = await graphql.query({
    query: gql`
      query ($id: String!) {
        user(id: $id) {
          email
          id
          name
          role
        }
      }
    `,
    variables: {
      id: params.userId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  })

  return json(response.data.user)
}

export const action: ActionFunction = async ({ params, request }) => {
  const formData = await request.formData()
  const action = formData.get('action')?.toString()
  const name = formData.get('name')?.toString()
  const password = formData.get('name')?.toString()

  if (action === 'update') {
    const response = await graphql.mutate({
      mutation: gql`
        mutation ($user: UpdateUserInput!) {
          updateUser(user: $user) {
            id
          }
        }
      `,
      variables: {
        user: { id: params.userId, name, password },
      },
      errorPolicy: 'all',
    })

    if (response.errors) {
      return json({ errors: response.errors })
    }
  }
  if (action === 'delete') {
    const anwser = window.confirm(
      'Você tem certeza que deseja apagar o usuário?',
    )
    if (anwser) {
      const response = await graphql.mutate({
        mutation: gql`
          mutation ($id: ID!) {
            deleteUser(id: $id)
          }
        `,
        variables: {
          id: params.userId,
        },
        errorPolicy: 'all',
      })

      if (response.errors) {
        return json({ errors: response.errors })
      }

      return redirect(`/cliente/${params.customerId}/usuarios`)
    }
  }

  return json({})
}

export const handle = {
  breadcrumb: ({ data, params }: RouteMatch & { data: User }) => (
    <Breadcrumb
      key={params.userId}
      to={`/cliente/${params.customerId}/usuarios/${params.userId}`}
    >
      {data.name}
    </Breadcrumb>
  ),
  title: ({ data }: { data: User }) => data.name,
}

function Component() {
  const loaderData = useLoaderData() as User
  const navigation = useNavigation()

  return (
    <>
      <div style={style.div}>
        <Form method='post' style={style.form}>
          <h2>Dados do usuário</h2>
          <input type='hidden' name='action' value='update' />
          <Input legend='Nome' name='name' defaultValue={loaderData.name} />
          <Input
            legend='Email'
            type='email'
            value={loaderData.email}
            readOnly
          />
          <Input legend='Nova senha' name='password' type='password' />
          <Button isLoading={navigation.state !== 'idle'} type='submit'>
            Atualizar usuário
          </Button>
        </Form>
      </div>
      <div style={style.div}>
        <Form method='post' style={style.form}>
          <h2>Apagar usuário</h2>
          <input type='hidden' name='action' value='delete' />
          <Button
            isLoading={navigation.state !== 'idle'}
            type='submit'
            outlined
          >
            <TrashIcon style={style.svg} />
            Apagar usuário
          </Button>
        </Form>
      </div>
    </>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    minWidth: '400px',
  },
  svg: {
    color: '#a01d21',
    height: '1.25rem',
    width: '1.25rem',
    marginRight: '0.5rem',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export const errorElement = <ErrorBoundary />

export const element = <Component />
