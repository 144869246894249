import { gql } from '@apollo/client'
import {
  json,
  LoaderFunction,
  Outlet,
  Form,
  useLoaderData,
  Link,
  useMatch,
  ActionFunction,
  useNavigation,
} from 'react-router-dom'

import { Customer } from '../../../types'
import { baseUrl, graphql } from '../api'
import FilesIcon from '../assets/files.svg?react'
import UsersIcon from '../assets/users.svg?react'
import Avatar from '../components/Avatar'
import Breadcrumb from '../components/Breadcrumb'
import Button from '../components/Button'
import ErrorBoundary from '../components/ErrorBoundary'
import Input from '../components/Input'
import Separator from '../components/Separator'

import * as Files from './Files'
import * as Users from './Users'

export const path = '/cliente/:customerId'

export const loader: LoaderFunction = async ({ params }) => {
  const response = await graphql.query({
    query: gql`
      query ($id: String!) {
        customer(id: $id) {
          avatar
          id
          name
        }
      }
    `,
    variables: {
      id: params.customerId,
    },
    errorPolicy: 'all',
  })

  return json(response.data.customer)
}

export const action: ActionFunction = async ({ params, request }) => {
  const formData = await request.formData()
  const action = formData.get('action')?.toString()

  if (action === 'update') {
    const { avatar, ...customer } = Object.fromEntries(formData.entries())

    if (avatar instanceof File && avatar.name) {
      const {
        data: { getSignedUrl },
      } = await graphql.mutate({
        mutation: gql`
          mutation ($key: String!) {
            getSignedUrl(key: $key) {
              key
              signedUrl
            }
          }
        `,
        variables: {
          key: `${params.customerId}/${avatar.name}`,
        },
      })

      await fetch(getSignedUrl.signedUrl, {
        method: 'put',
        body: avatar,
      })

      await graphql.mutate({
        mutation: gql`
          mutation ($key: String!, $deletedAt: String) {
            updateFile(key: $key, deletedAt: $deletedAt) {
              key
            }
          }
        `,
        variables: {
          key: getSignedUrl.key,
          deletedAt: `${+new Date()}`,
        },
      })
      customer.avatar = getSignedUrl.key
    }

    const response = await graphql.mutate({
      mutation: gql`
        mutation ($customer: UpdateCustomerInput!) {
          updateCustomer(customer: $customer) {
            avatar
            id
            name
          }
        }
      `,
      variables: {
        customer: {
          id: customer.id,
          avatar: customer.avatar,
          name: customer.name,
        },
      },
      errorPolicy: 'all',
    })

    return json(response.data.updateCustomer)
  } else if (action === 'delete') {
    // seu codigo
    // https://github.com/copertec/copertec/issues/293
  }
  return json({})
}

export const handle = {
  breadcrumb: ({ data }: { data: Customer }) => [
    <Breadcrumb key='customers' to='/'>
      Clientes
    </Breadcrumb>,
    <Separator key='customers_separator' />,
    <Breadcrumb key={data.id} to={`/cliente/${data.id}`}>
      {data.name}
    </Breadcrumb>,
  ],
  title: ({ data }: { data: Customer }) => data.name,
}

function Component() {
  const loaderData = useLoaderData() as Customer
  const match = useMatch(path)
  const navigation = useNavigation()
  const avatarId = loaderData.avatar?.split('/')?.at(1)

  return (
    <div style={style.div}>
      {match ? (
        <>
          <Form method='post' style={style.form} encType='multipart/form-data'>
            <h2>Dados da empresa</h2>
            <input type='hidden' name='action' value='update' />
            <input type='hidden' name='id' value={loaderData.id} />
            <input type='hidden' name='avatarId' value={avatarId} />
            <Avatar
              alt={loaderData.name}
              src={
                loaderData.avatar
                  ? `${baseUrl}/file/${loaderData.avatar}`
                  : undefined
              }
            />
            <Input
              legend='Nome da empresa'
              name='name'
              defaultValue={loaderData.name}
            />
            <Button isLoading={navigation.state === 'submitting'} type='submit'>
              Atualizar cliente
            </Button>
          </Form>
          <nav>
            <ul style={style.ul}>
              <li style={style.li}>
                <FilesIcon style={style.svg} />
                <Link style={style.a} to='arquivos' />
                Arquivos
              </li>
              <li style={style.li}>
                <UsersIcon style={style.svg} />
                <Link style={style.a} to='usuarios' />
                Usuários
              </li>
            </ul>
          </nav>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    minWidth: '400px',
  },
  ul: {
    display: 'flex',
    gap: '1rem',
  },
  li: {
    backgroundColor: 'white',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
    padding: '2rem',
    width: '100%',
    outlineColor: '#a01d21',
    color: 'gray',
    position: 'relative',
  },
  svg: {
    color: '#a01d21',
    height: '2rem',
    width: '2rem',
  },
  a: {
    position: 'absolute',
    color: 'black',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  img: {
    maxWidth: '10rem',
    width: '100%',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export const children = [Files, Users]

export const errorElement = <ErrorBoundary />

export const element = <Component />
