import { gql } from '@apollo/client'
import { Box } from 'caixa'
import dayjs from 'dayjs'
import {
  LoaderFunction,
  json,
  useLoaderData,
  Link,
  useParams,
  useMatch,
  Outlet,
  RouteMatch,
} from 'react-router-dom'

import { User } from '../../../../types'
import { graphql } from '../../api'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ErrorBoundary from '../../components/ErrorBoundary'

import * as Add from './Add'
import * as Update from './Update'

export const path = '/cliente/:customerId/usuarios'

export const id = 'users'

export const loader: LoaderFunction = async ({ params }) => {
  const users = await graphql.query({
    query: gql`
      query ($customerId: String!) {
        users(customerId: $customerId) {
          id
          name
        }
      }
    `,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    variables: {
      customerId: params.customerId,
    },
  })

  return json(users.data)
}

export const handle = {
  breadcrumb: ({ params }: RouteMatch) => (
    <Breadcrumb key={id} to={`/cliente/${params.customerId}/usuarios`}>
      Usuários
    </Breadcrumb>
  ),
  title: () => 'Usuários',
}

function Component() {
  const loaderData = useLoaderData() as { users: User[] }
  const params = useParams()
  const match = useMatch(path)

  return (
    <div style={style.div}>
      {match ? (
        <>
          <Box width='11rem' marginBottom='2rem'>
            <Link to={`/cliente/${params.customerId}/usuarios/adicionar`}>
              <Button>Adicionar Usuário</Button>
            </Link>
          </Box>
          <Box>
            <ul style={style.ul}>
              {loaderData.users.map((users) => (
                <li key={users.id} style={style.li}>
                  <Link
                    style={style.a}
                    to={`/cliente/${params.customerId}/usuarios/${users.id}`}
                  />
                  <h3>{users.name}</h3>
                  <p style={{ fontSize: '12px', color: '#bbbbbb' }}>
                    Criado em {dayjs(users.createdAt).format('DD/MM/YYYY')}
                  </p>
                </li>
              ))}
            </ul>
          </Box>
        </>
      ) : (
        <Outlet />
      )}
    </div>
  )
}

const style = {
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  ul: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min(10rem, 100%), 1fr))',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    listStyle: 'none',
  },
  li: {
    borderColor: 'gainsboro',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '0.1rem',
    padding: '1rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  a: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
  },
  img: {
    width: '4rem',
    borderRadius: '0.25rem',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const children = [Add, Update]

export const errorElement = <ErrorBoundary />

export const element = <Component />
