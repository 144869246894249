import { gql } from '@apollo/client'
import {
  json,
  LoaderFunction,
  Outlet,
  redirect,
  ScrollRestoration,
} from 'react-router-dom'

import { graphql } from '../api'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Title from '../components/Title'

import * as Customer from './Customer'
import * as Customers from './Customers'
import * as CustomerAdd from './Customers/Add'
import * as Dashboard from './Dashboard'
import * as Logout from './Logout'
import * as Profile from './Profile'

export const path = '/'

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url)
  const user = await graphql.query({
    query: gql`
      query {
        me {
          id
          email
          name
          role
          customer {
            avatar
            name
          }
        }
      }
    `,
    fetchPolicy: 'no-cache',
  })

  if (user.data.me.role === 'USER' && !url.pathname.includes('/arquivos'))
    return redirect('/arquivos')

  return json(user.data)
}

function Component() {
  return (
    <div style={style.div}>
      <Title />
      <ScrollRestoration />
      <Header />
      <main style={style.main}>
        <Outlet />
      </main>
    </div>
  )
}

const style = {
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
  main: {
    background: 'whitesmoke',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    margin: '0 auto',
    width: '100%',
    minHeight: '100%',
    padding: '2rem',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const children = [
  Customers,
  CustomerAdd,
  Customer,
  Dashboard,
  Logout,
  Profile,
]

export const errorElement = <ErrorBoundary />

export const element = <Component />
