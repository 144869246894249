import { useState } from 'react'

const Avatar: React.FC<{ alt: string; editable?: boolean; src?: string }> = ({
  alt,
  editable = true,
  src,
}) => {
  const [file, setFile] = useState<string>()
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files?.[0]) {
      setFile(URL.createObjectURL(event.target.files[0]))
    }
  }

  const imgSrc = file || src

  return (
    <div
      style={style.div}
      onMouseOver={() => setIsMouseOver(true)}
      onMouseOut={() => setIsMouseOver(false)}
    >
      {imgSrc ? (
        <img alt={alt} style={style.img} src={imgSrc} />
      ) : (
        <figure style={style.figure}>{alt.charAt(0).toUpperCase()}</figure>
      )}
      {editable ? (
        <input
          type='file'
          name='avatar'
          style={style.input}
          onChange={handleChange}
        />
      ) : null}
      {isMouseOver && editable ? <span style={style.span}>Editar</span> : null}
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    borderRadius: '0.25rem',
    cursor: 'pointer',
    display: 'flex',
    height: '10rem',
    justifyContent: 'center',
    margin: '0 auto',
    position: 'relative',
    textAlign: 'center',
    width: '10rem',
    overflow: 'hidden',
  },
  input: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    width: '100%',
    height: '100%',
    zIndex: '1',
    outline: 'none',
    cursor: 'pointer',
    opacity: '0',
  },
  img: {
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    margin: '0 auto',
    width: '100%',
  },
  span: {
    borderRadius: '0.25rem',
    position: 'absolute',
    color: 'white',
    fontSize: '1rem',
    backgroundColor: '#a01d21cc',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  figure: {
    borderRadius: '0.25rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: '5rem',
    color: '#a01d21',
    backgroundColor: '#a01d2155',
    width: '100%',
    height: '100%',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export default Avatar
