import SpinnerIcon from '../assets/spinner.svg?react'

const Button: React.FC<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & {
    isLoading?: boolean
    outlined?: boolean
    small?: boolean
    inline?: boolean
    text?: boolean
  }
> = ({ isLoading, outlined, small, inline, text, ...props }) => (
  <button
    {...props}
    style={{
      ...props.style,
      ...style.button,
      ...(small
        ? {
            height: '2rem',
            padding: '0.85rem',
            fontSize: '0.85rem',
            display: 'flex',
            whiteSpace: 'nowrap',
          }
        : {}),
      ...(outlined
        ? {
            backgroundColor: '#a01d21',
            color: '#ffffff',
            border: '2px solid #a01d21',
          }
        : {}),
      ...(inline
        ? {
            backgroundColor: '#ffffff',
            color: '#a01d21',
            border: '2px solid #a01d21',
          }
        : {}),
      ...(text
        ? {
            backgroundColor: '#ffffff',
            color: '#a01d21',
            border: 'none',
          }
        : {}),
    }}
    children={isLoading ? <SpinnerIcon style={style.svg} /> : props.children}
  />
)

const style = {
  button: {
    backgroundColor: '#a01d21',
    borderRadius: '0.25rem',
    color: '#ffffff',
    cursor: 'pointer',
    fontSize: '1rem',
    height: '3rem',
    padding: '1rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  svg: {
    animation: 'rotation 1.25s infinite linear',
    height: '1.5rem',
    width: '1.5rem',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export default Button
