const Input: React.FC<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { legend: string; message?: string }
> = ({ legend, message, ...props }) => {
  return (
    <fieldset style={style.fieldset}>
      <legend style={style.legend}>{legend}</legend>
      <input style={style.input} {...props} />
      {message ? <span style={style.span}>{message}</span> : null}
    </fieldset>
  )
}

const style = {
  fieldset: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
  },
  legend: {
    marginBottom: '0.75rem',
  },
  input: {
    borderColor: 'black',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: '1rem',
    height: '3rem',
    padding: '1rem',
    width: '100%',
    outlineColor: '#a01d21',
  },
  span: {
    color: '#a01d21',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export default Input
