import { ApolloError } from '@apollo/client'
import Cookies from 'js-cookie'
import { Navigate, useRouteError } from 'react-router-dom'

import copertec from '../assets/copertec.png'

import Anchor from './Anchor'

function ErrorBoundary() {
  const error = useRouteError()

  if (error instanceof ApolloError) {
    Cookies.remove('copertec_jwt')
    return <Navigate to='/entrar' />
  }

  return (
    <div style={style.div}>
      <img alt='Copertec' src={copertec} style={style.img} />
      <h3>Ocorreu um erro</h3>
      <p style={style.p}>
        Entre em contato pelo email{' '}
        <Anchor href='mailto:suporte@copertec-eng.com.br'>
          suporte@copertec-eng.com.br
        </Anchor>
        .
      </p>
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    justifyContent: 'center',
    padding: '1rem',
    width: '100%',
  },
  img: {
    maxWidth: '16rem',
    width: '100%',
  },
  p: {
    color: 'gray',
    textAlign: 'center',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export default ErrorBoundary
