import { PropsWithChildren, useState } from 'react'

import EllipsisVertical from '../assets/ellipsis-vertical.svg?react'
import { useOutsideClick } from '../utils/useOutsideClick'

const Actions: React.FC<PropsWithChildren> = ({ children }) => {
  const [isClicked, setIsClicked] = useState<boolean>(false)

  const ref = useOutsideClick(() => {
    setIsClicked(false)
  })

  return (
    <div style={style.div}>
      <EllipsisVertical onClick={() => setIsClicked(true)} style={style.svg} />
      {isClicked ? (
        <nav style={style.nav} ref={ref}>
          <ul style={style.ul}>{children}</ul>
        </nav>
      ) : null}
    </div>
  )
}

const style = {
  div: {
    display: 'block',
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  svg: {
    color: '#a01d21',
    height: '1.5rem',
    width: '1.5rem',
    cursor: 'pointer',
    position: 'relative',
  },
  nav: {
    backgroundColor: 'white',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '0.1rem',
    width: '12rem',
    padding: '1rem',
    position: 'absolute',
    top: '0',
    right: '1.5rem',
    zIndex: '1',
  },
  ul: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
} satisfies Partial<
  Record<
    string | keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export default Actions
