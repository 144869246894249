import { gql } from '@apollo/client'
import {
  json,
  Form,
  ActionFunction,
  redirect,
  useNavigation,
  RouteMatch,
} from 'react-router-dom'

import { graphql } from '../../api'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ErrorBoundary from '../../components/ErrorBoundary'
import Input from '../../components/Input'

export const path = '/cliente/:customerId/usuarios/adicionar'

export const action: ActionFunction = async ({ params, request }) => {
  const formData = await request.formData()

  const user = Object.fromEntries(formData.entries())

  const response = await graphql.mutate({
    mutation: gql`
      mutation ($user: CreateUserInput!, $customerId: String) {
        createUser(user: $user, customerId: $customerId) {
          id
        }
      }
    `,
    variables: {
      user,
      customerId: params.customerId,
    },
    errorPolicy: 'all',
  })

  if (response.errors) {
    return json({ errors: response.errors })
  }

  return redirect(
    `/cliente/${params.customerId}/usuarios/${response.data.createUser.id}`,
  )
}

export const handle = {
  breadcrumb: ({ params }: RouteMatch) => (
    <Breadcrumb to={`/clientes/${params.customerId}/usuarios/adicionar`}>
      Adicionar
    </Breadcrumb>
  ),
  title: () => 'Novo usuário',
}

function Component() {
  const navigation = useNavigation()

  return (
    <div style={style.div}>
      <Form method='post' style={style.form}>
        <h2>Dados do usuário</h2>
        <Input legend='Nome' name='name' />
        <Input legend='Email' name='email' type='email' />
        <Input legend='Senha' name='password' type='password' />
        <input name='role' type='hidden' value='USER' />
        <Button isLoading={navigation.state !== 'idle'} type='submit'>
          Adicionar usuário
        </Button>
      </Form>
    </div>
  )
}

const style = {
  div: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2rem',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    gap: '2rem',
    padding: '2rem',
    backgroundColor: 'white',
    minWidth: '400px',
  },
  ul: {
    display: 'flex',
    gap: '1rem',
  },
  li: {
    backgroundColor: 'white',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    gap: '1rem',
    padding: '2rem',
    width: '100%',
    outlineColor: '#a01d21',
    color: 'gray',
    position: 'relative',
  },
  svg: {
    color: '#a01d21',
    height: '2rem',
    width: '2rem',
  },
  a: {
    position: 'absolute',
    color: 'black',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  img: {
    maxWidth: '10rem',
    width: '100%',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export const errorElement = <ErrorBoundary />

export const element = <Component />
