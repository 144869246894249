import { Link, useLoaderData } from 'react-router-dom'

import { Customer } from '../../../types'
import { baseUrl } from '../api'
import CopertecLogo from '../assets/copertec.png'
import RightFromBracketIcon from '../assets/right-from-bracket.svg?react'

import Breadcrumbs from './Breadcrumbs'

const Header = () => {
  const loaderData = useLoaderData() as { me: { customer?: Customer } }
  return (
    <header style={style.header}>
      <nav style={style.nav}>
        <ul style={style.ul}>
          <li>
            <Link to={loaderData?.me?.customer ? '/arquivos' : '/'}>
              <img
                alt={
                  loaderData?.me?.customer
                    ? loaderData?.me?.customer.name
                    : 'Logo Copertec'
                }
                src={
                  loaderData?.me?.customer
                    ? `${baseUrl}/file/${loaderData?.me?.customer.avatar}`
                    : CopertecLogo
                }
                style={style.img}
              />
            </Link>
          </li>
        </ul>
        <ul style={style.ul}>
          <li>
            <Link to='/perfil' style={style.a}>
              Perfil
            </Link>
          </li>
          <li>
            <Link to='/sair' style={style.a}>
              Sair <RightFromBracketIcon style={style.svg} />
            </Link>
          </li>
        </ul>
      </nav>
      <Breadcrumbs />
    </header>
  )
}

const style = {
  header: {},
  nav: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },
  div: {
    width: '100%',
    padding: '1rem',
  },
  img: {
    maxHeight: '4rem',
    height: '100%',
  },
  ul: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  a: {
    display: 'flex',
    gap: '0.3rem',
    color: '#a01d21',
    fontWeight: 'bold',
    padding: '1rem',
  },
  svg: {
    color: '#a01d21',
    height: '1.25rem',
    width: '1.25rem',
  },
} satisfies Partial<
  Record<
    keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export default Header

//'#a01d21'
