import Cookies from 'js-cookie'
import { LoaderFunction, redirect } from 'react-router-dom'

export const path = '/sair'

export const id = 'logout'

export const loader: LoaderFunction = ({ request }) => {
  const url = new URL(request.url)
  Cookies.remove('copertec_jwt', { domain: `.${url.hostname}` })
  return redirect('/entrar')
}
