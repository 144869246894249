import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

export const baseUrl = import.meta.env.PROD
  ? `https://api.${import.meta.env.VITE_DOMAIN_NAME}`
  : 'http://localhost:3000'

const httpLink = createHttpLink({
  uri: `${baseUrl}/graphql`,
  credentials: 'include',
})

export const graphql = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
  credentials: 'include',
})

export const rest = (uri: string, init: RequestInit) =>
  fetch(`${baseUrl}/${uri}`, init)
