import { PropsWithChildren } from 'react'
import { Link, LinkProps } from 'react-router-dom'

const Breadcrumb: React.FC<LinkProps & PropsWithChildren> = (props) => {
  return <Link {...props} style={style} />
}

const style = {
  backgroundColor: 'whitesmoke',
  color: 'gray',
} satisfies React.CSSProperties

export default Breadcrumb
