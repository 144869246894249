import { Box } from 'caixa'
import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import {
  Link,
  useParams,
  Form,
  useSubmit,
  useNavigate,
  useNavigation,
  useLocation,
} from 'react-router-dom'

import { baseUrl } from '../api'
import ChevronLeft from '../assets/chevron-left.svg?react'
import FileArrowUp from '../assets/file-arrow-up.svg?react'
import FolderPlus from '../assets/folder-plus.svg?react'
import FolderIcon from '../assets/folder.svg?react'
import Loader from '../assets/loader.svg?react'
import QrCode from '../assets/qrcode.svg?react'
import Trash from '../assets/trash.svg?react'
import { dedupe } from '../utils/dedupe'
import { getFileIcon } from '../utils/fileIcon'

import Action from './Action'
import Actions from './Actions'
import Button from './Button'

const FileBrowser: React.FC<{
  items: {
    key: string
    name: string
    type: 'FILE' | 'FOLDER'
  }[]
  withActions?: boolean
  isAdmin?: boolean
  customerId?: string
}> = ({ items, withActions = true, isAdmin, customerId }) => {
  const [isCreatingNewFolder, setIsCreatingNewFolder] = useState(false)
  const [isSelectingFiles, setIsSelectingFiles] = useState(false)
  const [selectedFilesForDownload, setSelectedFilesForDownload] = useState<
    string[]
  >([])
  const params = useParams()
  const createFolderRef = useRef<HTMLInputElement>(null)
  const selectFilesRef = useRef<HTMLInputElement>(null)
  const [selectFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedFile, setSelectedFile] = useState<{
    url: string
    name: string
  }>()
  const submit = useSubmit()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const location = useLocation()

  useEffect(() => {
    if (isCreatingNewFolder) {
      createFolderRef.current?.focus()
    }
  }, [isCreatingNewFolder])

  useEffect(() => {
    const listener = (
      event: HTMLElementEventMap['mousedown' | 'touchstart'],
    ) => {
      if (
        !createFolderRef.current ||
        createFolderRef.current.contains(event.target as Node) ||
        createFolderRef.current.value
      ) {
        return
      }
      setIsCreatingNewFolder(false)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [createFolderRef])

  useEffect(() => {
    if (navigation.state === 'idle') {
      setIsCreatingNewFolder(false)
      setSelectedFiles([])
    }
  }, [navigation, items])

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)
      setSelectedFiles(files)
    }
  }

  const copyImageToClipboard = () =>
    toast.promise(
      new Promise((resolve, reject) => {
        const canvas = document.querySelector('canvas')
        if (canvas) {
          canvas.toBlob(async (blob) => {
            if (blob) {
              await navigator.clipboard.write([
                new ClipboardItem({
                  [blob.type]: blob,
                }),
              ])
              resolve(true)
            } else {
              reject(false)
            }
          })
        } else {
          reject(false)
        }
      }),
      {
        loading: 'Copiando QR code...',
        success: 'QR code copiado com sucesso!',
        error: 'Erro ao copiar QR code',
      },
    )

  useEffect(() => {
    if (selectedFile) {
      copyImageToClipboard()
    }
  }, [selectedFile])

  return (
    <Box width='100%' display='flex' flexDirection='column' gap='1rem'>
      <div
        style={{
          display: 'none',
        }}
      >
        {selectedFile ? (
          <QRCodeCanvas value={encodeURI(selectedFile.url)} size={512} />
        ) : null}
      </div>

      <nav style={style.nav}>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ChevronLeft onClick={() => navigate(-1)} style={style.svg} />
          {isSelectingFiles ? (
            <Box
              display='flex'
              gap='2rem'
              justifyContent='center'
              alignItems='center'
            >
              <p>{selectedFilesForDownload.length} arquivo(s) selecionado(s)</p>
              <div>
                <Button
                  outlined
                  small
                  inline
                  onClick={() => {
                    setSelectedFilesForDownload(
                      items
                        .filter((item) => item.type === 'FILE')
                        .map((item) => item.key),
                    )
                  }}
                >
                  Selecionar todos arquivos
                </Button>
              </div>
            </Box>
          ) : null}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          {isSelectingFiles ? (
            <>
              <Form action={location.pathname} method='post'>
                <input
                  type='hidden'
                  name='customerId'
                  value={customerId ?? params.customerId}
                />
                <input type='hidden' name='action' value='zip' />
                <input
                  type='hidden'
                  name='keys'
                  value={selectedFilesForDownload.join(',')}
                />
                <Button
                  small
                  type='submit'
                  disabled={selectedFilesForDownload.length === 0}
                >
                  Baixar arquivos
                </Button>
              </Form>
              <Form action={location.pathname} method='post'>
                <input
                  type='hidden'
                  name='customerId'
                  value={customerId ?? params.customerId}
                />
                <input type='hidden' name='action' value='delete' />
                <input
                  type='hidden'
                  name='keys'
                  value={selectedFilesForDownload.join(',')}
                />
                <Button
                  inline
                  small
                  type='submit'
                  disabled={selectedFilesForDownload.length === 0}
                >
                  Deletar arquivos
                </Button>
              </Form>
              <Button
                text
                small
                onClick={() => {
                  setIsSelectingFiles(false)
                  setSelectedFilesForDownload([])
                }}
              >
                Cancelar
              </Button>
            </>
          ) : (
            <>
              <Button
                outlined
                small
                inline
                onClick={() => setIsSelectingFiles(true)}
              >
                Selecionar arquivos
              </Button>{' '}
              {isAdmin ? (
                <>
                  <div>
                    <FileArrowUp
                      onClick={() => selectFilesRef.current?.click()}
                      style={style.svg}
                    />
                  </div>
                  <div>
                    <FolderPlus
                      onClick={() => setIsCreatingNewFolder(true)}
                      style={style.svg}
                    />
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>
      </nav>

      <Form
        action={location.pathname}
        method='post'
        encType='multipart/form-data'
        onChange={(event) => submit(event.currentTarget)}
      >
        <input type='hidden' name='action' value='files' style={style.hidden} />
        <input
          type='hidden'
          name='prefix'
          value={params['*']}
          style={style.hidden}
        />
        <input
          name='files'
          multiple
          ref={selectFilesRef}
          type='file'
          onChange={onChange}
          style={style.hidden}
        />
      </Form>
      <ul style={style.ul}>
        {isCreatingNewFolder ? (
          <li style={style.li}>
            <FolderIcon style={style.svg} />
            <Form action={location.pathname} method='post'>
              <Box display='flex' flexDirection='column' gap='1rem'>
                <input
                  type='hidden'
                  name='action'
                  value='folder'
                  style={style.hidden}
                />
                <input
                  type='hidden'
                  name='prefix'
                  value={params['*']}
                  style={style.hidden}
                />
                <input
                  name='name'
                  placeholder='Nome da Pasta'
                  ref={createFolderRef}
                  style={style.input}
                />
                <Button
                  type='submit'
                  isLoading={navigation.state === 'submitting'}
                >
                  Criar pasta
                </Button>
              </Box>
            </Form>
          </li>
        ) : null}
        {dedupe<(typeof items)[number]>(
          [
            ...items,
            ...(selectFiles as unknown as {
              key: string
              name: string
              type: 'FILE' | 'FOLDER'
            }[]),
          ],
          'name',
        )
          .sort((a, b) => {
            if (a.type === 'FOLDER' && b.type === 'FOLDER')
              return a.name.localeCompare(b.name)
            if (a.type === 'FOLDER') return -1
            if (b.type === 'FOLDER') return 1
            // const nameA = a.name.replace(/\s/g, '')
            // const nameB = b.name.replace(/\s/g, '')
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              ignorePunctuation: true,
            })
          })
          .map((item) => {
            const Icon = item.type === 'FOLDER' ? FolderIcon : getFileIcon('')
            return (
              <li
                title={item.name}
                key={item.name}
                style={{
                  ...style.li,
                  backgroundColor: selectedFilesForDownload.includes(item.key)
                    ? '#a01d2125'
                    : 'white',
                }}
              >
                {item instanceof File ? null : (
                  <Link
                    to={
                      item.type === 'FOLDER'
                        ? params.customerId
                          ? `/cliente/${params.customerId}/arquivos${
                              params['*'] ? `/${params['*']}/` : '/'
                            }${item.name}`
                          : `/arquivos${
                              params['*'] ? `/${params['*']}/` : '/'
                            }${item.name}`
                        : `${baseUrl}/file/${item.key}`
                    }
                    target={item.type === 'FILE' ? '__bank' : '_self'}
                    style={style.a}
                    onClick={(e) => {
                      if (isSelectingFiles) {
                        e.preventDefault()
                        if (item.type === 'FILE') {
                          if (selectedFilesForDownload.includes(item.key)) {
                            setSelectedFilesForDownload(
                              selectedFilesForDownload.filter(
                                (itemKey) => itemKey !== item.key,
                              ),
                            )
                          } else {
                            setSelectedFilesForDownload([
                              ...selectedFilesForDownload,
                              item.key,
                            ])
                          }
                        }
                      }
                    }}
                  />
                )}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {item instanceof File ? (
                    <Loader
                      className='rotate'
                      style={{
                        ...style.svg,
                        animation: 'rotation 1.25s infinite linear',
                      }}
                    />
                  ) : (
                    <Icon style={style.svg} />
                  )}
                  {item instanceof File ? null : withActions ? (
                    <Actions>
                      {item.type === 'FILE' ? (
                        <Action
                          itemKey={item.key}
                          action='qr'
                          label='QR Code'
                          icon={QrCode}
                          onClick={() =>
                            setSelectedFile({
                              url: `${baseUrl}/file/${item.key}`,
                              name: item.name,
                            })
                          }
                        />
                      ) : null}
                      <Action
                        itemKey={item.key}
                        action='delete'
                        label='Apagar'
                        icon={Trash}
                        type={item.type}
                      />
                    </Actions>
                  ) : null}
                </div>
                <p style={style.p}>{item.name}</p>
              </li>
            )
          })}
      </ul>
    </Box>
  )
}

const style = {
  ul: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min(14rem, 100%), 1fr))',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    listStyle: 'none',
  },
  li: {
    backgroundColor: 'white',
    borderColor: 'gainsboro',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '0.1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
    position: 'relative',
  },
  p: {
    fontSize: '1rem',
    wordBreak: 'break-all',
  },
  a: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
  },
  svg: {
    color: '#a01d21',
    height: '1.75rem',
    width: '1.75rem',
    cursor: 'pointer',
  },
  input: {
    fontSize: '1.25rem',
    outline: 'none',
    width: '100%',
  },
  hidden: {
    display: 'none',
    opacity: 0,
    width: 0,
    height: 0,
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
  },
} satisfies Partial<
  Record<
    string | keyof HTMLElementTagNameMap | keyof SVGElementTagNameMap,
    React.CSSProperties
  >
>

export default FileBrowser
