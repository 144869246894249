import { gql } from '@apollo/client'
import { Box } from 'caixa'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import {
  ActionFunction,
  json,
  Link,
  LoaderFunction,
  redirect,
  useLoaderData,
} from 'react-router-dom'

import { Customers, User } from '../../../../types'
import { baseUrl, graphql } from '../../api'
import Avatar from '../../components/Avatar'
import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import ErrorBoundary from '../../components/ErrorBoundary'

export const index = true

export const loader: LoaderFunction = async () => {
  const customers = await graphql.query({
    query: gql`
      query {
        customers {
          avatar
          id
          name
        }
      }
    `,
    fetchPolicy: 'no-cache',
  })

  return json(customers.data)
}

export const action: ActionFunction = ({ request }) => {
  if (request.method === 'DELETE') {
    Cookies.remove('copertec_jwt')
    return redirect('/entrar')
  }
  return json(null)
}

export const handle = {
  breadcrumb: () => <Breadcrumb to='/'>Clientes</Breadcrumb>,
  title: () => 'Clientes',
}

function Component() {
  const loaderData = useLoaderData() as { me: User; customers: Customers }

  return (
    <div style={style.div}>
      <Box width='11rem' marginBottom='2rem'>
        <Link to='/clientes/adicionar'>
          <Button>Adicionar Cliente</Button>
        </Link>
      </Box>
      <ul style={style.ul}>
        {loaderData.customers.map((customer) => (
          <li key={customer.id} style={style.li}>
            <Link style={style.a} to={`/cliente/${customer.id}`} />
            <Avatar
              alt={customer.name}
              editable={false}
              src={
                customer.avatar
                  ? `${baseUrl}/file/${customer.avatar}`
                  : undefined
              }
            />

            <h3>{customer.name}</h3>
            <p style={{ fontSize: '12px', color: '#bbbbbb' }}>
              Criado em {dayjs(customer.createdAt).format('DD/MM/YYYY')}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

const style = {
  div: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
  },
  ul: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(min(12rem, 100%), 1fr))',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    listStyle: 'none',
  },
  li: {
    borderColor: 'gainsboro',
    backgroundColor: 'white',
    borderRadius: '0.25rem',
    borderStyle: 'solid',
    borderWidth: '0.1rem',
    padding: '1rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  a: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '1',
  },
  img: {
    width: '4rem',
    borderRadius: '0.25rem',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export const errorElement = <ErrorBoundary />

export const element = <Component />
