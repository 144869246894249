const Alert: React.FC<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props) => <div {...props} style={{ ...props.style, ...style }} />

const style = {
  backgroundColor: 'gold',
  padding: '1rem',
  borderRadius: '0.25rem',
} satisfies React.CSSProperties

export default Alert
