import { useMatches } from 'react-router-dom'

import Separator from './Separator'

const Breadcrumbs = () => {
  const matches = useMatches()
  const breadcrumbs = matches
    .filter(
      (match) =>
        typeof match.handle === 'object' &&
        match.handle !== null &&
        'breadcrumb' in match.handle,
    )
    .map((match) =>
      (match.handle as { breadcrumb: (match: unknown) => string }).breadcrumb(
        match,
      ),
    )

  return (
    <ol style={style.ol}>
      {breadcrumbs.map((breadcrumb, index) => (
        <li key={index}>
          {breadcrumb}
          {index < breadcrumbs.length - 1 ? <Separator key={index} /> : null}
        </li>
      ))}
    </ol>
  )
}

const style = {
  ol: {
    backgroundColor: 'whitesmoke',
    display: 'flex',
    padding: '1rem 2rem',
    color: 'gray',
  },
} satisfies Partial<Record<keyof HTMLElementTagNameMap, React.CSSProperties>>

export default Breadcrumbs
